import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Link from "next/link";

import getShoppableDispensariesNearby from "api/requests/consumerApi/getShoppableDispensariesNearby";
import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";
import { buildLocationUrls } from "utils/buildLocationUrls";
import logError from "utils/logError";

import DispensaryCard from "components/botanic/DispensaryCard";
import DispensaryCardPlaceholder from "components/botanic/DispensaryCard/DispensaryCardPlaceholder";
import Placeholder, { Rect } from "components/Placeholder";

const NearbyStores: React.FC<{
  className?: string;
  dispensaryId: number;
  locations: Dispensary["locations"];
  locationPaths?: Dispensary["locationPaths"];
}> = ({ className, dispensaryId, locations, locationPaths }) => {
  const baseUrl = "/dispensaries";
  const { cityUrl, stateUrl } = buildLocationUrls({
    baseUrl,
    locationPaths,
  });

  const [dispensaries, setDispensaries] = useState<Dispensary[]>([]);
  const [loading, setLoading] = useState(true);

  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent(
      Category.dispensaryMenu,
      Action.impression,
      "conquest ad carousel",
      {
        dispensaryId,
      },
    );

    const getDispensaries = async () => {
      setLoading(true);

      const coordinates = locations[0];

      try {
        const dispensaries = await getShoppableDispensariesNearby(coordinates);

        setLoading(false);
        setDispensaries(dispensaries.slice(0, 3));
      } catch (e) {
        logError(e.message, {
          functionName: "NearbyStores",
          service: "consumer",
          statusCode: e.statusCode,
        });

        setLoading(false);
      }
    };

    getDispensaries();
  }, []);

  return (
    <div className={classnames(className)}>
      <h2 data-testid="nearby-stores" className="mb-lg">
        Discover great stores near you
      </h2>

      {dispensaries.length ? (
        <div className="row">
          {dispensaries.map((dispensary, i) => {
            return (
              <div
                key={dispensary.slug}
                className="col md:col-1/3 mb-sm md:mb-none"
              >
                <DispensaryCard
                  currentStatus={dispensary.currentStatuses?.store}
                  data-testid="nearby-stores__dispensary-card"
                  deliveryEnabled={dispensary.deliveryEnabled}
                  disableLazyLoad
                  dispensaryId={dispensary.id}
                  distanceMi={dispensary.distanceMi}
                  hideCTA
                  inStoreCartEnabled={dispensary.inStoreCartEnabled}
                  key={`dispensary-${dispensary.slug}-${i}`}
                  logoUrl={dispensary.logoUrl}
                  name={dispensary.name}
                  onClick={() =>
                    trackEvent(
                      Category.dispensaryMenu,
                      Action.click,
                      "conquest ad carousel",
                      {
                        dispensaryId,
                      },
                    )
                  }
                  pickupEnabled={dispensary.pickupEnabled}
                  preorderConfigured={dispensary.preorderConfigured}
                  preserveHeight
                  rating={dispensary.rating}
                  reviewCount={dispensary.reviewCount}
                  scheduleType="store"
                  slug={dispensary.slug}
                  tags={dispensary.tags}
                  timeZone={dispensary.timeZone}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {loading && (
            <div className="row" data-testid="nearby-stores_placeholders">
              {["a", "b", "c"].map((n) => (
                <div key={n} className="col md:col-1/3 mb-sm md:mb-none">
                  <DispensaryCardPlaceholder
                    id={`nearby-stores_placeholder-${n}`}
                    preserveHeight
                    hideCTA
                  />
                </div>
              ))}
            </div>
          )}

          {!loading && (
            <div className="flex flex-col items-center justify-center p-xl text-center min-h-[684px] md:min-h-[228px]">
              <p className="mb-xl">
                Sorry, we couldn't find any nearby stores…
              </p>
            </div>
          )}
        </>
      )}

      <div className="text-center mt-xl">
        {loading && (
          <Placeholder width="176" height="24" className="mx-auto">
            <Rect width="100%" height="100%" />
          </Placeholder>
        )}

        {!loading && (
          <Link
            className="mx-auto underline text-sm"
            data-testid="nearby-stores__see-more-stores"
            href={cityUrl || stateUrl || baseUrl}
            onClick={() =>
              trackEvent(
                Category.dispensaryMenu,
                Action.click,
                "conquest ad carousel - see more stores nearby link",
                {
                  dispensaryId,
                },
              )
            }
          >
            See more stores nearby
          </Link>
        )}
      </div>
    </div>
  );
};

export default NearbyStores;
